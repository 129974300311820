import noUiSlider from "nouislider";

const defaultMax = 2000;
const defaultConfig = {
  priceMin: 0,
  priceMax: defaultMax,
  step: 1000,
  sliderMin: 0,
  range: defaultMax,
  valueFormat: "@",
  bucketValues: [[0, defaultMax]],
};
const format = (val) => new Intl.NumberFormat('en-CA', 
  { style: 'currency', currency: 'CAD', maximumFractionDigits: 0 })
  .format(val);
function initSliders() {
    var rangeSliders = document.getElementsByClassName("js-price-slider");
    for (let index = 0; index < rangeSliders.length; index++) {
      const sliderContainer = rangeSliders[index];
      const sliderControl = sliderContainer.querySelector(".slider-control");

      if (sliderControl.noUiSlider) {
        continue;
      }

      sliderContainer.addEventListener("click", (e) => {
        e.stopPropagation();
      });
      const customSliderConfig = JSON.parse(sliderContainer.dataset.priceslider);
      var sliderConfig = { ...defaultConfig, ...customSliderConfig };
    
      const fromPriceInput = sliderContainer.querySelector(".js-priceslider-from");
      const toPriceInput = sliderContainer.querySelector(".js-priceslider-to");
      
      fromPriceInput.disabled = true;
      toPriceInput.disabled = true;

      const theSlider = noUiSlider.create(sliderControl, {
        start: [sliderConfig.priceMin, sliderConfig.priceMax],
        step: sliderConfig.step,
        margin: sliderConfig.step,
        connect: true,
        range: {
          min: [sliderConfig.sliderMin],
          max: [sliderConfig.range],
        },
      });
    
      theSlider.on("update", (p1, p2, values) => {
        fromPriceInput.value = format(values[0]);
        toPriceInput.value = format(values[1]);
      });
    
      theSlider.on("change", (p1, p2, values) => {
        const vals = values.map((value) =>
          parseInt(encodeURIComponent(Math.round(value)), 10)
        );
        let url;
        if (
          Math.round(sliderConfig.sliderMin) === vals[0] &&
          Math.round(sliderConfig.range) === vals[1]
        ) {
          url = sliderConfig.relaxURL;
        } else {
          let valFrom = vals[0];
          let valTo = vals[1];
          url = sliderConfig.url
            .replace(/(pmin)=([^&]+)/, `pmin=${valFrom}`)
            .replace(/(pmax)=([^&]+)/, `pmax=${valTo}`);
        }
        const sliderElement = theSlider.target;
        sliderElement.dataset.href = url;
    
        $(sliderElement).trigger('change');
      });
    }
}

initSliders();

$(document).on('filter:update', initSliders);
$(document).on('refinementBanner:update', initSliders);
