import noUiSlider from "nouislider";

const format = (val, styling) => new Intl.NumberFormat('en-CA', styling).format(val);

function appendNewQueryParam(url, refId, min, max) {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  let maxIndex = 0;

  for (const [key, _] of params) {
      if (key.startsWith('prefn')) {
          const index = parseInt(key.substring(5));
          if (index > maxIndex) {
              maxIndex = index;
          }
      }
  }

  maxIndex = maxIndex === 0 ? 1 : maxIndex + 1;
  params.append(`prefn${maxIndex}`, refId);
  params.append(`prefmin${maxIndex}`, min);
  params.append(`prefmax${maxIndex}`, max);
  urlObj.search = params.toString();

  return urlObj.toString();
}

function getFt(inch) {
  var inch = parseInt(inch);
  return Math.round(inch/12);
}

function initSliders() {
    var rangeSliders = document.getElementsByClassName("js-attribute-slider");
    for (let index = 0; index < rangeSliders.length; index++) {
      const sliderContainer = rangeSliders[index];
      const sliderControl = sliderContainer.querySelector(".attribute-slider-control");
      if (sliderControl.noUiSlider) {
        continue;
      }
      sliderContainer.addEventListener("click", (e) => {
        e.stopPropagation();
      });
      const sliderConfig = JSON.parse(sliderContainer.dataset.priceslider);
      const fromPriceInput = sliderContainer.querySelector(".js-attribute-slider-from");
      const toPriceInput = sliderContainer.querySelector(".js-attribute-slider-to");
      
      fromPriceInput.disabled = true;
      toPriceInput.disabled = true;
      var config = {
        start: [sliderConfig.sliderMin, sliderConfig.sliderMax],
        step: sliderConfig.step,
        connect: true,
        range: {
          min: sliderConfig.minValue,
          max: sliderConfig.maxValue
        },
      };
      if(sliderConfig.id === 'length') {
        config.format = {
          to: function (value) {
              return getFt(value);
          },
          from: function (value) {
              return getFt(value)
          }
        }
        config.range.min = getFt(config.range.min);
        config.range.max = getFt(config.range.max);
      };
      const theSlider = noUiSlider.create(sliderControl, config);
    
      theSlider.on("update", (p1, p2, values) => {
        fromPriceInput.value = format(values[0], sliderConfig.styling);
        toPriceInput.value = format(values[1], sliderConfig.styling);
      });
    
      theSlider.on("change", (p1, p2, values) => {
        var $sliderElement = $(theSlider.target);
        var refId =$sliderElement.data('refinement');
        const vals = values.map((value) =>
          parseInt(encodeURIComponent(Math.round(value)), 10)
        );
        var min = vals[0];
        var max = vals[1];
        if(refId === 'length') {
          min = Math.floor(min*12)
          max = Math.floor(max*12)
        }
        let url = appendNewQueryParam(sliderConfig.url, refId, min, max);
        
        $sliderElement.data('href', url);
        $sliderElement.trigger('change');
      });
    }
}
initSliders();
$(document).on('filter:update', initSliders);
$(document).on('refinementBanner:update', initSliders);
