'use strict';

// List of allowed refinement names, can be updated to dynamic list
const refinementNames = ['price-range', 'sleeps', 'dry-weight', 'length', 'biweekly-payments'];

/**
 * Updates the refinement display based on the checked checkboxes.
 * @param {string} refinementType - The unique part of the refinement type (e.g., 'rv-type', 'location').
 */
function updateBooleanRefinement(refinementType) {
    const selector = `.card-block#refinement-${refinementType}`;
    // const textSelector = `#refinement-${refinementType}-text`; // Replace the title/name of the refinement opener
    const countSelector = `#refinement-${refinementType}-1`;
    const refinementValues = `.refinement-${refinementType}-values`;

    const checkboxes = $(selector).find('input:checkbox:checked').length;
    const checkboxesItems = [];

    $(selector + ' ul li').each(function () {
        const displayName = $(this).find('input:checkbox:checked').attr('data-displayname');
        if (displayName !== undefined) {
            checkboxesItems.push(displayName);
        }
    });

    if (checkboxes > 0) {
        // $(textSelector).empty().append(checkboxesItems[0]); // Replace the title/name of the refinement opener
        $(refinementValues).closest('.refinement-bar-title').addClass('active');
        $(refinementValues).empty().append(checkboxesItems[0]);
        const checkcount = checkboxes - 1;
        if (checkcount > 0) {
            $(countSelector).empty().append('+' + checkcount);
        } else {
            $(countSelector).empty().append('');
        }
    } else {
        $(refinementValues).closest('.refinement-bar-title').removeClass('active');
    }
}

function formatNumber(number) {
    const numberStr = number.toString();

    if (numberStr.endsWith('000')) {
        return numberStr.slice(0, -3) + 'K';
    }

    if (numberStr.length > 4 && numberStr.endsWith('00')) {
        return numberStr.slice(0, -3) + '.' + numberStr.slice(-3, -2) + 'K';
    }

    return numberStr;
}

function formatDisplayString(refinementName, rangeValue) {
    const formattedRangeValue = rangeValue.map(formatNumber);

    let displayString;
    switch (refinementName) {
        case 'price-range':
            displayString = `$ ${formattedRangeValue[0]} - $ ${formattedRangeValue[1]}`;
            break;
        case 'sleeps':
            displayString = `${formattedRangeValue[0]} - ${formattedRangeValue[1]}`;
            break;
        case 'dry-weight':
            displayString = `${formattedRangeValue[0]} lbs - ${formattedRangeValue[1]} lbs`;
            break;
        case 'length':
            displayString = `${formattedRangeValue[0]} - ${formattedRangeValue[1]}`;
            break;
        case 'biweekly-payments':
            displayString = `$ ${formattedRangeValue[0]} - $ ${formattedRangeValue[1]}`;
            break;
        default:
            displayString = `${formattedRangeValue[0]} - ${formattedRangeValue[1]}`;
            break;
    }

    return displayString;
}

/**
 * Updates the refinement display based on the input values.
 * @param {string} refinementName - The unique part of the refinement name (e.g., 'price-range', 'sleeps', 'dry-weight').
 */
function updateRefinementDisplay(refinementName, urlParams) {
    if (window.searchRefinementBannerUrl) {
        // Parent Selector name
        const parentSelector = `.refinement-${refinementName}`;
        // Selector name where values should be displayed
        const valuesSelector = `.refinement-${refinementName}-values`;
        const parentElement = $(parentSelector);

        // Find the child element with the data-refinement attribute
        const refinementId = parentElement.find('[data-refinement]').data('refinement');

        // Check if the refinement ID is in the URL parameters
        let refinementIdInUrl = false;
        // let refinementPriceInUrl = false;

        if (refinementName === 'price-range') {
            refinementIdInUrl = urlParams.some(param => param.split('=')[0].includes('pmin'));
        } else {
            refinementIdInUrl = urlParams.some(param => param.split('=')[1].includes(refinementId));
        }

        // If the refinement ID is in the URL, update the values element
        if (refinementIdInUrl) {
            // String to display
            let rangeValue = parentElement.find('[data-range-value]').data('range-value');

            // Price values correction
            if (refinementName === 'price-range') {
                rangeValue[0] > 0 ? rangeValue[0] += 1 : rangeValue[0];
                rangeValue[1] -= 1;
            }

            const displayString = formatDisplayString(refinementName, rangeValue);

            // Update sliders header text
            parentElement.find(valuesSelector).text(displayString);
            $(valuesSelector).closest('.refinement-bar-title').addClass('active');
        } else {
            $(valuesSelector).closest('.refinement-bar-title').removeClass('active');
        }
    }
}

function refinementFilterAjaxCall(e) {
    e.preventDefault();
    e.stopPropagation();

    $.spinner().start();

    // Do we need this?
    $(this).trigger('search:filter', e);


    var refinementID = $(this).data('refinementid');
    var refinementValue = $(this).data('refinementvalue');
    var url = $(this).data('href');

    // Need to test, currently we have no radio refinements
    var radiotype = $(this).data('radiotype');
    if (radiotype) {
        var urlArray = url.split('?');
        var refinementNumber;
        var refValue;
        urlArray[1].split('&').forEach(function (ele) {
            if (ele.includes(refinementID) && ele.includes('prefn')) {
                refinementNumber = ele[5];
            }
        });
        urlArray[1].split('&').forEach(function (ele) {
            if (ele.includes('prefv' + refinementNumber)) {
                refValue = 'prefv' + refinementNumber + '=' + encodeURIComponent(refinementValue);
                url = url.replace(ele, refValue);
            }
        });
    }

    // Global url for searchRefinements()
    window.searchRefinementBannerUrl = url;

    var topBlockID = $(this).closest('.card-block').attr('id');
    $.ajax({
        url: url,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: $(this).data('href'),
            refinementsConfigured: $('.refinements').data('refinementsconfigured')
        },
        method: 'GET',
        success: function (response) {
            $('.refinements').empty().html($(response).html());
            $.spinner().stop();

            // Keep refinement dropdown opened after selection
            $('.card-block#' + topBlockID).find('.card-header').addClass('current');
            $('.card-block#' + topBlockID).find('.card-body').addClass('active');

            // Update selected refinements
            updateBooleanRefinement('rv-type');
            updateBooleanRefinement('location');

            // Call the function for each refinement name
            var urlParams = url.split('?')[1].split('&');
            refinementNames.forEach(refinementName => {
                updateRefinementDisplay(refinementName, urlParams);
            });

            $(document).trigger('refinementBanner:update');
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    refinementBannerFilter: function () {
        // Handle refinement value selection
        $('body').on('click', '.swatch-square-block', refinementFilterAjaxCall);
        $('.refinement-bar').on('change', '.slider-control', refinementFilterAjaxCall);
        $('.refinement-bar').on('change', '.attribute-slider-control', refinementFilterAjaxCall);
    },
    searchRefinements: function () {
        $('body').on('click', '.refinements-search', function () {
            var url = window.searchRefinementBannerUrl;
            var url = url ? url.replace('ShowBannerRefinment', 'Show') : $(this).data('url');
            window.location.href = url;
        });
    },
    RefinementsList: function () {
        $('body').on('click', '.card-header', function () {
            if ($(this).hasClass('current')) {
                $('.card-header').removeClass('current');
                $(this).next('.card-body').removeClass('active');
            } else {
                $('.card-body').removeClass('active');
                $('.card-header').removeClass('current');
                $(this).addClass('current');
                $(this).next('.card-body').addClass('active');
            }
        });
        $('body').click(function (e) {
            if (!$(e.target).closest('.card-header').length) {
                $('.card-body').removeClass('active');
                $('.card-header').removeClass('current');
            }
        });
        $('body').on('click', '.card-body', function (e) {
            e.stopPropagation();
        });
    },
    refinementsOnLoad: function () {
        updateBooleanRefinement('location');
    }
};
